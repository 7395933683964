import * as React from "react"
import { withPrismicPreviewResolver, WithPrismicPreviewResolverProps } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "../utils/linkResolver"
import { Link, PageProps } from "gatsby"
import { PageContext } from "../types"

declare global {
  interface Window {
    isPrismicPreview: boolean | null
    prismicPreviewPath?: string
    isPrismicPreviewReloaded: boolean
  }
}

const PreviewPage = ({ isPrismicPreview, prismicPreviewPath }: PageProps<GatsbyTypes.PrismicAllDocumentTypes, PageContext> & WithPrismicPreviewResolverProps) => {
  // Make preview status available on window so homepage index can redirect appropriately
  React.useEffect(() => {
    window.isPrismicPreview = isPrismicPreview
    window.prismicPreviewPath = prismicPreviewPath
    window.isPrismicPreviewReloaded = false
  }, [isPrismicPreview])

  return (
    <div className="text w-full h-screen flex items-center justify-center">
      {isPrismicPreview ? (
        <p>Loading</p>
      ) : (
        <p>
          Nothing to preview, <Link to="/">go home</Link>.
        </p>
      )}
    </div>
  )
}

export default withPrismicPreviewResolver(PreviewPage, [{
  repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY ?? ``,
  linkResolver,
}])
